<template lang="pug">
  .bulk-settings
    p.inventory-group-title {{ title }}
    h2.inventory-group-name {{ filters.reference.name }}
    .car-classes-table
      .car-classes-content
        table
          thead
            tr
              th.on-off
                span {{ $t('sale_stop_settings.on_off') }}
              th.car-class
                span {{ $t('sale_stop_settings.car_class') }}
              th.available-inventory-column
                span {{ $t('sale_stop_settings.available_inventory') }}
              th.otas-name
                span {{ $t('sale_stop_settings.stop_selling_ota') }}
              th.actions
          tbody
            tr.matchings-item.car-class
              td.on-off
                AppToggle(
                  :value="active"
                  @change="toggleItem(!$v.active.$model)"
                )
              td
                span {{ $t('sale_stop_settings.all_car_classes') }}
              td(colspan="3")
                .subsettings
                  .subsetting(
                    v-for="(setting, index) in $v.subsettings.$each.$iter"
                    :key="setting.id"
                  )
                    .available_inventory
                      AppNumberInput.available-inventory(
                        :disabled="!$v.active.$model"
                        :invalid="setting.available_inventory.$error"
                        :value="setting.available_inventory.$model"
                        @input="changeSettingItem(index, 'available_inventory', $event)"
                      )
                    .otas
                      AppDropdown.ota-name.pl-0(
                        :disabled="!$v.active.$model"
                        :class="{ invalid: setting.ota_ids.$error }"
                        close-on-select
                        searchable
                        batch-select
                        multiple
                        value-key="id"
                        title-key="name"
                        :placeholder="$t('sale_stop_settings.not_selected')"
                        :value="selectedOtas(setting.ota_ids.$model)"
                        :items="otaList"
                        :is-item-disabled="item => isItemDisabled(index, item)"
                        @select="changeSettingItem(index, 'ota_ids', $event.map(({id}) => id).sort())"
                        order-direction="keep"
                      )
                    .actions
                      .add
                        AppButtonWithIcon.add-new-icon(
                          v-if="$v.subsettings.$model.length - 1 === +index"
                          icon="plus-circle"
                          :disabled="!$v.active.$model"
                          @click="addSetting"
                        )
                      .delete
                        AppButtonWithIcon.delete-icon(
                          icon="trash-alt"
                          @click="deleteSetting(index)"
                        )
    .d-flex.justify-content-end
      .error(v-if="$v.subsettings.$error")
        span(v-if="some(subsettings, { available_inventory: null })") {{ $t('sale_stop_settings.error.empty_inventory') }}
        span(v-if="some(subsettings, { ota_ids: null })") {{ $t('sale_stop_settings.error.empty_otas') }}
      AppButton.btn-confirm(
        title="actions.apply_changes"
        @click="bulkUpdate"
      )
</template>

<script>
  import { mapState } from "vuex"
  import { bus } from "@/config"
  import { isSharedInventoryEnabled } from "@/helpers/organization"
  import { some } from "lodash-es"

  // mixins
  import withValidations from "@/mixins/withValidations"
  import otaMethods from "../otaMethods"

  const validationsMixin = withValidations(({ required, requiredIf, minValue, integer, minLength }) => ({
    active: { required },
    subsettings: {
      $each: {
        active: { required },
        available_inventory: {
          required: requiredIf(function(subsetting) {
            return subsetting.active
          }),
          integer,
          minValue: minValue(1)
        },
        ota_ids: {
          required: requiredIf(function(subsetting) {
            return subsetting.active
          }),
          minLength: minLength(1)
        }
      }
    }
  }))

  export default {
    mixins: [validationsMixin, otaMethods],

    props: {
      otaAccounts: {
        type: Array,
        default: () => Array
      }
    },

    components: {
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      AppButtonWithIcon: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    data() {
      return {
        active: false,
        subsettings: [{ id: null, active: false, available_inventory: null, ota_ids: null }]
      }
    },

    computed: {
      ...mapState("saleStopSettings", ["filters"]),

      title() {
        return isSharedInventoryEnabled()
          ? this.$t("sale_stop_settings.bulk_setting.selected_inventory_group")
          : this.$t("sale_stop_settings.bulk_setting.selected_shop")
      }
    },

    methods: {
      some,

      toggleItem(active) {
        this.active = active

        this.subsettings = this.subsettings.map(s => ({ ...s, active }))
      },

      changeSettingItem(index, field, value) {
        const newSettings = this.subsettings.map((setting, i) => {
          if (i === +index) {
            return { ...setting, [field]: value }
          }
          return { ...setting }
        })

        this.subsettings = newSettings
      },

      addSetting() {
        const newSettings = [...this.subsettings, { ...this.generateEmptySetting(), active: this.active }]

        this.subsettings = newSettings
      },

      deleteSetting(index) {
        let newSettings = this.subsettings.filter((_, i) => i !== +index)

        if (newSettings.length === 0) {
          newSettings = [{ ...this.generateEmptySetting(), active: this.active }]
        }

        this.subsettings = newSettings
      },

      generateEmptySetting() {
        return {
          id: null,
          available_inventory: null,
          ota_ids: null
        }
      },

      bulkUpdate() {
        const { active, subsettings } = this

        this.validateAttributes()

        if (this.isValid) {
          bus.emit("bulk-update-sale-stop-settings", {
            active,
            subsettings
          })
          this.$emit("close")
        }
      },

      isItemDisabled(index, { id }) {
        return some(this.subsettings, ({ ota_ids }, i) => (index !== i ? ota_ids?.includes(id) : false))
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .bulk-settings
    margin-bottom: -$modal-padding
    width: 800px

    .inventory-group-title
      font-size: 14px
      color: $default-gray
      margin-bottom: 5px
    .inventory-group-name
      font-size: 16px
      font-weight: bold
      margin-bottom: 10px
    .car-classes
      &-table
        +matchings-table-header
        margin-top: 10px
        margin-bottom: 10px
        overflow-y: auto
        height: 250px

        table
          width: 100%
          margin-bottom: 20px

          th
            &.on-off
              +cell-width(100px)
              width: 100px
            &.car-class
              +cell-width(300px)
            &.available-inventory-column
              +cell-width(100px)
              width: 100px
            &.otas-name
              +cell-width(100px)
            &.actions
              +cell-width(76px)
              width: 76px
          tr
            vertical-align: baseline
          td
            +matchings-item-td

            &.on-off
              padding-left: 0

            span
              text-wrap: nowrap

            ::v-deep
              .app-number-input
                input.field
                  width: 100px
                  height: 28px

                &.invalid
                  .btn
                    +default-invalid-input
              &.app-toggle
                margin-left: 10px

          .subsettings
            display: flex
            flex-direction: column
            .subsetting
              width: 100%
              display: flex
              align-items: center

              .available_inventory
                margin-right: 26px
                flex: 0 0 64px

                ::v-deep
                  input
                    font-weight: 400
                    font-size: 13px
                    line-height: 13px
                    width: 100%
                    text-align: right
                    padding-right: 8px
                    height: 34px


              .otas
                padding: 0 10px
                flex: 1 1 100%
                max-width: 240px
                width: 240px


              .actions
                display: flex
                align-items: center
                justify-content: space-between
                flex: 0 0 60px
                margin-left: auto

                .add-new-icon, .delete-icon
                  +icon-button($default-purple)

    .error
      display: flex
      flex-direction: column
      color: $default-red
      font-weight: 400
      font-size: 13px
      margin-right: 24px
      height: 34px
      line-height: 13px
      justify-content: center
</style>
